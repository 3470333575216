import React, { Component } from "react";

import AboutMe from "./aboutMe";

export default class Home extends Component {
  render() {
    return (
      <>
        <AboutMe />
      </>
    );
  }
}
