import React, { Component } from "react";

export default class NotFound extends Component {
  render() {
    return (
      <>
        <p>404 page not found</p>
      </>
    );
  }
}
